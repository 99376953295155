<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            {{ dealData.clientFirstName }} {{ dealData.clientLastName }}
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <v-expansion-panels :value="0">
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  {{ dealData.clientFirstName }} {{ dealData.clientLastName }}
                  <v-icon
                    class="ml-4"
                    style="cursor: pointer"
                    @click="
                      $router.push({
                        path: `/dashboard/deal/edit/${dealId}`,
                      })
                    "
                    >mdi-account-edit-outline</v-icon
                  >
                </h3>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col>
                    <p class="caption">Client Information</p>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Deal Name</td>
                          <td>
                            {{ dealData.clientFirstName }}
                            {{ dealData.clientLastName }}
                          </td>
                        </tr>
                        <tr>
                          <td>Deal Status</td>

                          <td>
                            {{ dealData.status }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                  <v-col>
                    <p class="caption">Location Information</p>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Location</td>
                          <td>{{ dealData.city }}, {{ dealData.province }}</td>
                        </tr>
                        <tr>
                          <td>Location Notes</td>
                          <td>
                            {{ dealData.locationNotes }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>

                <br />

                <v-row>
                  <v-col>
                    <p class="caption">Deal Ratios</p>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>LTV</td>
                          <td>{{ dealData.ltv }}%</td>
                        </tr>

                        <tr>
                          <td>GDS</td>
                          <td>{{ dealData.gds }}%</td>
                        </tr>

                        <tr>
                          <td>TDS</td>
                          <td>{{ dealData.tds }}%</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                  <v-col>
                    <p class="caption">Proposed Terms</p>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Loan Amount</td>
                          <td>{{ dealData.amountRequested | formatPrice }}</td>
                        </tr>
                        <tr>
                          <td>Mortgage Position</td>
                          <td>{{ dealData.mortgagePosition }}</td>
                        </tr>
                        <tr>
                          <td>Proposed Term</td>

                          <td>
                            {{ dealData.proposedTerm }}
                          </td>
                        </tr>
                        <tr>
                          <td>Proposed Rate</td>

                          <td>{{ dealData.proposedRate }}%</td>
                        </tr>
                        <tr>
                          <td>Proposed Lender Fee</td>

                          <td>{{ dealData.proposedLenderFee }}%</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>

                <br />

                <v-row>
                  <v-col>
                    <p class="caption">Property Valuation</p>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Property Value</td>
                          <td>${{ dealData.propertyValue }}</td>
                        </tr>
                        <tr>
                          <td>Property Value Notes</td>
                          <td>{{ dealData.propertyValueNotes }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                  <v-col>
                    <p class="caption">Property Encumberances</p>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Owing Against</td>
                          <td>${{ dealData.propertyOwing }}</td>
                        </tr>
                        <tr>
                          <td>Owing Against Notes</td>
                          <td>
                            {{ dealData.propertyOwingNotes }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>

                <br />

                <v-row>
                  <v-col>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Amount Requested</td>
                          <td>${{ dealData.amountRequested }}</td>
                        </tr>

                        <tr>
                          <td>Amount Requested Notes</td>
                          <td>{{ dealData.amountRequestNotes }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                  <v-col>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Amount Requested</td>
                          <td>${{ dealData.amountRequested }}</td>
                        </tr>

                        <tr>
                          <td>Amount Requested Notes</td>
                          <td>{{ dealData.amountRequestNotes }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>

                <br />

                <v-row>
                  <v-col>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Income Notes</td>

                          <td>
                            {{ dealData.incomeNotes }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Liability Notes</td>

                          <td>
                            {{ dealData.liabilityNotes }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Credit Notes</td>

                          <td>
                            {{ dealData.creditNotes }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Summary</td>

                          <td>
                            {{ dealData.summaryNotes }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-simple-table dense>
                      <thead>
                        <th :width="250"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Exit Strategy</td>

                          <td>
                            {{ dealData.exitStrategy }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>

                <br />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-5">
        <v-col xs="12" xl="6">
          <v-expansion-panels>
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  Lenders
                  <v-icon
                    class="ml-4"
                    style="cursor: pointer"
                    @click.stop="addNote"
                    >mdi-currency-usd</v-icon
                  >
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table v-if="assignedLenders.length" dense>
                  <thead>
                    <th class="text-left">Lender Name</th>
                    <th class="text-left">Lender Type</th>
                    <th class="text-left">Prop. Fee</th>
                    <th class="text-left">Prop. Rate</th>
                    <th class="text-left">Active</th>
                    <th class="text-left">Actions</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(lender, index) in assignedLenders"
                      :key="lender.id"
                    >
                      <td>{{ lender.lenderName }}</td>
                      <td>{{ lender.lenderType }}</td>
                      <td>{{ lender.proposedFee }}</td>
                      <td>{{ lender.proposedRate }}</td>
                      <td>
                        <v-switch
                          dense
                          v-if="!assignedLenders[index].loading"
                          v-model="lender.active"
                          @change="toggleLenderActive(index)"
                          style="
                            margin-top: 5px;
                            margin-bottom: 5px;
                            line-height: 0px !important;
                            height: 30px;
                            display: inline;
                          "
                          :disabled="assignedLenders[index].loading"
                        ></v-switch>
                        <div v-if="assignedLenders[index].loading == true">
                          <v-progress-circular
                            indeterminate
                            color="primary"
                          ></v-progress-circular>
                        </div>
                      </td>
                      <td>
                        <div
                          v-if="
                            dealData.status == 'awarded' &&
                            assignedLenders[index].accepted
                          "
                        >
                          <span style="color: green; font-weight: bold"
                            >Awarded</span
                          >
                          <span
                            @click="unaward(lender.lenderId)"
                            style="color: red"
                            class="ml-3"
                            >X</span
                          >
                        </div>

                        <v-btn
                          v-if="dealData.status != 'awarded'"
                          small
                          @click="awardDeal(lender.lenderId)"
                          >Award</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>

                <div v-else>
                  <p>There are no assigned lenders for this deal.</p>
                </div>

                <div class="mt-4">
                  <v-btn
                    v-if="assignedLenders.length"
                    dark
                    @click="
                      $router.push({
                        path: `/dashboard/deal/resendDealToLenders/${dealData.id}`,
                      })
                    "
                    >Resend Deal To Lenders</v-btn
                  >
                  &nbsp; &nbsp;
                  <v-btn
                    dark
                    @click="
                      $router.push({
                        path: `/dashboard/deal/assignLenders/${dealData.id}`,
                      })
                    "
                    >Assign Lenders</v-btn
                  >
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-5">
        <v-col xs="12" xl="6">
          <v-expansion-panels>
            <v-expansion-panel style="background-color: transparent">
              <v-expansion-panel-header>
                <h3>
                  Deal Notes
                  <v-icon
                    class="ml-4"
                    style="cursor: pointer"
                    @click.stop="addNote"
                    >mdi-notebook-plus-outline</v-icon
                  >
                </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <insert-note
                  v-if="addNoteActive"
                  :dealId="dealData.id"
                  :insertToTable="'deal'"
                  @closeNote="closeNote"
                  @refreshNotes="refreshNotes"
                ></insert-note>

                <div v-if="loadingNotes">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                <div v-if="!loadingNotes">
                  <div v-for="dealNote in dealNotes" :key="dealNote.id">
                    <note
                      :noteProp="dealNote"
                      :noteType="'dealNote'"
                      @refreshNotes="refreshNotes"
                    ></note>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "DealRead",
  props: ["dealId"],
  components: {
    insertNote: () => import("@/components/note/InsertNote.vue"),
    Note: () => import("@/components/note/Note.vue"),
  },
  data() {
    return {
      panel: 0,
      loadingNotes: false,
      addNoteActive: false,
      formLoading: false,
      componentLoading: true,
      submitError: "",
      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },
      dealData: {},
      dealContacts: {},
      dealAddresses: {},
      dealNotes: {},
      dealAccounts: {},
      assignedLenders: {},
    };
  },

  methods: {
    async unaward(lenderId) {
      let unawardConfirm = confirm(
        "Are you sure you want to unaward the deal?"
      );
      if (unawardConfirm) {
        let dealUnaward = await API().post("/dealUnaward", {
          dealId: this.dealId,
          lenderId: lenderId,
        });

        if (dealUnaward.status == 200) {
          this.loadPageData();
        } else {
          alert("the deal was not awarded, there was an error");
        }
      } else {
        alert("you did not award it");
      }
    },
    async awardDeal(lenderId) {
      let awarded = confirm("Are you sure you want to award the deal?");
      if (awarded) {
        let dealAward = await API().post("/dealAward", {
          dealId: this.dealId,
          lenderId: lenderId,
        });

        if (dealAward.status == 200) {
          this.loadPageData();
        } else {
          alert("the deal was not awarded, there was an error");
        }
      } else {
        alert("you did not award it");
      }
    },
    async toggleLenderActive(index) {
      this.$set(this.assignedLenders[index], "loading", true);
      // this.assignedLenders[index].loading = true;

      let modifyLenderActive = await API().patch("/lenderDealPivotActive", {
        id: this.assignedLenders[index].id,
        active: this.assignedLenders[index].active,
      });

      if (modifyLenderActive.status == 202) {
        console.log("the status is 202");
        this.assignedLenders[index].loading = false;
      } else {
        alert(
          "there was an error updating the status!!! status was not updated"
        );
      }
    },
    async refreshNotes() {
      console.log("refresh notes firing read");
      await API()
        .get(`/dealNotes/${this.dealId}`)
        .then((res) => {
          this.dealNotes = res.data;
          this.loadingNotes = false;
          this.closeNote();
        })
        .catch((e) => {
          console.log("the catch block in refreshnotes is firing");
          console.log(e);
        });
    },
    addNote() {
      this.addNoteActive = true;
    },
    closeNote() {
      this.addNoteActive = false;
    },
    submitForm() {
      this.loading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.formRules.valid) {
        API()
          .post("/deal/add", this.dealData)
          .then((res) => {
            this.loading = false;

            alert(JSON.stringify(res.data));
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
    loadPageData() {
      this.componentLoading = true;
      API()
        .get(`/deal/read/${this.dealId}`)
        .then((res) => {
          console.log(res);
          this.dealData = res.data.recordsets[0][0];
          this.dealNotes = res.data.recordsets[1];
          this.assignedLenders = res.data.recordsets[2];

          this.componentLoading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.loadPageData();
  },
  filters: {
    formatPrice(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },

    booleanString(x) {
      if (x == true) {
        return "Yes";
      } else {
        return "No";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>